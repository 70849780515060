<template>
    <div>
        <form @submit.prevent="">
            <div class="form-row">
                <div class="col-12">
                    <a href="" id="example-number-input5" @click.prevent="showSearch()"
                        class="w100-w tgju-btn tgju-btn-lg tgju-btn-danger"><i class="uil uil-apps"></i> انتخاب شاخص</a>
                </div>
                <div class="col-12" v-if="indicator">
                    <div class="col-12 pr-0 pt-3" style="padding-right: 0 !important;text-align: center;">
                        <label for="example-number-input5" style="margin-bottom: 0 !important;">شاخص فعلی : {{
                            indicator.title }}</label>
                    </div>
                </div>
            </div>
            <div class="form-row text-center mt-4">
                <div class="col-12">
                    <a href="" @click.prevent="saveChanges()"
                        class="m-left min-w150 tgju-btn tgju-btn-lg tgju-btn-primary"><i class="uil uil-save"></i> ذخیره</a>
                </div>
            </div>
        </form>

        <!-- showSearch() برای اضافه شدن سرچ این دایو اضافه گردید -->
        <div id="tgsearch" style="display: none;">
            <div class="tgcss-search-modal  tgcss-fixed tgcss-inset-0 tgcss-z-30">
                <div
                    class="tgcss-fixed tgcss-inset-0 tgcss-z-30 tgcss-flex tgcss-justify-center tgcss-content-center lg:tgcss-pb-5 tgcss-items-start">
                    <div class="tgcss-z-5 tgcss-search-btn tgcss-fixed tgcss-inset-0  tgcss-bg-[#000]  tgcss-opacity-40"
                        slot @click="hideSearch"></div>
                    <div class="tgcss-z-6 tgcss-search-btn tgcss-fixed tgcss-inset-0 tgcss-bg-white  dark:tgcss-bg-[#2a2a2a] tgcss-h-17"
                        slot @click="hideSearch"></div>
                    <div
                        class="tgcss-flex tgcss-flex-col tgcss-relative tgcss-z-10 tgcss-w-full tgcss-h-full lg:tgcss-w-[60rem]">
                        <div class="tgcss-bg-white tgcss-py-2.5 tgcss-px-3 lg:tgcss-px-0 dark:tgcss-bg-[#2a2a2a]">
                            <div class="tgcss-rounded-full tgcss-overflow-hidden">
                                <div class="tgcss-flex tgcss-w-full tgcss-bg-gray-100 tgcss-relative">
                                    <label for="search-input" class="tgcss-p-3 rtl:tgcss-pl-0 ltr:tgcss-pr-0">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor"
                                            class="tgcss-w-6 tgcss-h-6 tgcss-text-gray-500 rtl:tgcss-ml-3 ltr:tgcss-mr-3">
                                            <path fill-rule="evenodd"
                                                d="M10.5 3.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zM2.25 10.5a8.25 8.25 0 1114.59 5.28l4.69 4.69a.75.75 0 11-1.06 1.06l-4.69-4.69A8.25 8.25 0 012.25 10.5z"
                                                clip-rule="evenodd" />
                                        </svg>
                                    </label>
                                    <input @keyup="search()" v-model="search_text"
                                        class="tgcss-w-full tgcss-bg-gray-100 tgcss-p-3 rtl:tgcss-pr-1 ltr:tgcss-pl-1 tgcss-text-sm tgcss-border-0 focus:tgcss-outline-none"
                                        id="tgcss-search-input" placeholder="عنوان جستجو را وارد کنید ...">
                                    <div v-if="search_loading == true" class="tgcss-flex tgcss-items-center tgcss-ml-3">
                                        <span class="tgcss-relative tgcss-flex tgcss-h-3 tgcss-w-3">
                                            <span
                                                class="tgcss-animate-ping tgcss-absolute tgcss-inline-flex tgcss-h-full tgcss-w-full tgcss-rounded-full tgcss-bg-gray-500 tgcss-opacity-75"></span>
                                        </span>
                                    </div>
                                    <div class="tgcss-flex tgcss-items-center tgcss-px-3 tgcss-pb-1 tgcss-cursor-pointer"
                                        @click="searchClear()">
                                        <span
                                            class="tgcss-text-gray-400 tgcss-text-xs tgcss-font-medium hover:tgcss-text-gray-500">
                                            خالی‌کردن
                                        </span>
                                    </div>

                                    <div class="tgcss-flex tgcss-items-center">
                                        <span class="tgcss-h-5 tgcss-w-[1px] tgcss-bg-gray-200"></span>
                                    </div>

                                    <div class="tgcss-flex tgcss-items-center tgcss-px-2 tgcss-cursor-pointer tgcss-search-btn"
                                        slot @click="hideSearch">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                            stroke-width="2" stroke="currentColor"
                                            class="tgcss-w-8 tgcss-h-8 tgcss-text-gray-500 hover:tgcss-bg-gray-200 tgcss-p-2 tgcss-rounded-full">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div
                            class="tgcss-flex tgcss-flex-col lg:tgcss-max-h-[600px] lg:tgcss-mt-4 tgcss-h-auto tgcss-bg-white dark:tgcss-bg-[#3c3c3c] lg:tgcss-rounded-md tgcss-pb-0 tgcss-absolute tgcss-w-full tgcss-top-[70px] tgcss-bottom-0 lg:tgcss-bottom-auto tgcss-overflow-hidden">
                            <div class="tgcss-overflow-auto tgcss-h-14 tgcss-min-h-[55px] -tgcss-mb-[7px]">
                                <div class="tgcss-flex tgcss-w-full tgcss-pt-3 tgcss-pb-1 tgcss-px-4">
                                    <div id="tgcss-main-tabs"
                                        class="tgcss-flex tgcss-w-full tgcss-border-b-4 tgcss-border-gray-100 dark:tgcss-border-[#2a2a2a] dark:tgcss-text-white">
                                        <div v-for="(header, index) in headers" v-bind:key="index">
                                            <div v-if="header.title == active_header.title" @click="selectHeader(header)"
                                                class="tgcss-whitespace-nowrap tgcss-border-b-4 tgcss-border-gray-900 dark:tgcss-border-white rtl:tgcss-ml-2 ltr:tgcss-mr-2 -tgcss-mb-1 tgcss-py-1 tgcss-pb-2 tgcss-px-3 tgcss-font-bold tgcss-text-sm tgcss-cursor-pointer hover:tgcss-opacity-70">
                                                {{ header.title }}</div>
                                            <div v-if="header.title != active_header.title" @click="selectHeader(header)"
                                                class="tgcss-whitespace-nowrap tgcss-border-b-4 tgcss-border-gray-100 dark:tgcss-border-[#2a2a2a] rtl:tgcss-ml-2 ltr:tgcss-mr-2 -tgcss-mb-1 tgcss-py-1 tgcss-pb-2 tgcss-px-3 tgcss-font-bold tgcss-text-sm tgcss-cursor-pointer hover:tgcss-opacity-70">
                                                {{ header.title }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div v-if="active_header.title != 'همه' && active_header.title != 'ابزارها' && active_header.title != 'تکنیکال'"
                                class="tgcss-flex tgcss-border-b tgcss-border-b-gray-100 dark:tgcss-border-b-[#2a2a2a]">
                                <div
                                    class="tgcss-flex tgcss-w-full tgcss-pb-4 tgcss-pt-3 tgcss-px-4 tgcss-overflow-x-auto tgcss-min-h-[55px]">
                                    <div v-for="(section, index) in active_header.sections" v-bind:key="index">
                                        <div v-if="section.title == active_section.title" @click="selectSection(section)"
                                            class="tgcss-bg-gray-900 tgcss-whitespace-nowrap dark:tgcss-text-[#2a2a2a] dark:hover:tgcss-text-white dark:tgcss-bg-white dark:tgcss-border-white hover:dark:tgcss-bg-[#636363] hover:dark:tgcss-border-[#636363] tgcss-text-white tgcss-rounded-full rtl:tgcss-ml-2 ltr:tgcss-mr-2 tgcss-py-1 tgcss-px-3 tgcss-font-normal tgcss-text-sm tgcss-cursor-pointer">
                                            {{ section.title }}</div>
                                        <div v-if="section.title != active_section.title" @click="selectSection(section)"
                                            class="tgcss-bg-gray-100 tgcss-whitespace-nowrap dark:tgcss-text-white dark:tgcss-bg-[#555555] dark:tgcss-border-[#555555] hover:dark:tgcss-bg-[#636363] hover:dark:tgcss-border-[#636363] hover:tgcss-bg-gray-200 tgcss-rounded-full rtl:tgcss-ml-2 ltr:tgcss-mr-2 tgcss-py-1 tgcss-px-3 tgcss-font-normal tgcss-text-sm tgcss-cursor-pointer">
                                            {{ section.title }}</div>
                                    </div>
                                </div>
                            </div>

                            <slot v-cloak v-if="filter == true && def != true"
                                class="tgcss-border-b tgcss-border-gray-100 dark:tgcss-border-[#2a2a2a] tgcss-py-[10px] tgcss-leading-3">

                                <div class="tgcss-inline-block">
                                    <div class="tgcss-flex">
                                        <div
                                            class="tgcss-flex tgcss-items-center rtl:tgcss-mr-4 ltr:tgcss-ml-4 tgcss-cursor-pointer">
                                            <div v-for="(item, key, index) in catList" v-bind:key="index"
                                                class="tgcss-flex tgcss-items-center">
                                                <div v-if="item.list"
                                                    class="tgcss-bg-gray-100 dark:tgcss-bg-[#2a2a2a] rtl:tgcss-ml-2 ltr:tgcss-mr-2 tgcss-flex tgcss-items-center tgcss-rounded-md tgcss-px-4 rtl:tgcss-pl-2 ltr:tgcss-pr-2 tgcss-py-1 tgcss-text-sm tgcss-font-medium">
                                                    <div v-if="catSelect[key] != undefined"
                                                        class="tgcss-flex tgcss-items-center"
                                                        @click="setCatList(key, item.list)">
                                                        {{ catSelect[key].name }}
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                            class="tgcss-w-4 tgcss-h-4 rtl:tgcss-mr-2 ltr:tgcss-ml-2 ">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                                        </svg>
                                                    </div>
                                                    <div v-else class="tgcss-flex tgcss-items-center"
                                                        @click="setCatList(key, item.list)">
                                                        {{ item.name }}
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                            viewBox="0 0 24 24" stroke-width="2" stroke="currentColor"
                                                            class="tgcss-w-4 tgcss-h-4 rtl:tgcss-mr-2 ltr:tgcss-ml-2">
                                                            <path stroke-linecap="round" stroke-linejoin="round"
                                                                d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                                                        </svg>
                                                    </div>
                                                </div>
                                                <slot v-if="catSelect[key] != undefined"
                                                    class="tgcss-flex tgcss-items-center">
                                                    <svg @click="setCat(null, key)" xmlns="http://www.w3.org/2000/svg"
                                                        fill="none" viewBox="0 0 24 24" stroke-width="2"
                                                        stroke="currentColor"
                                                        class="tgcss-w-4 tgcss-h-4 rtl:tgcss-ml-2 ltr:tgcss-mr-2 rtl:-tgcss-mr-1 ltr:-tgcss-ml-1 hover:tgcss-bg-gray-200 tgcss-rounded-full">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            d="M6 18 18 6M6 6l12 12" />
                                                    </svg>
                                                </slot>
                                            </div>
                                        </div>
                                        <div class="tgcss-grow"></div>
                                    </div>
                                    <div v-if="catSelectFilterList.length"
                                        class="tgcss-absolute tgcss-inset-0 tgcss-bg-white dark:tgcss-bg-[#3c3c3c] tgcss-z-20 tgcss-top-0 tgcss-h-full">
                                        <div
                                            class="tgcss-border-b tgcss-border-b-gray-100 dark:tgcss-border-b-[#2a2a2a] tgcss-flex">
                                            <div @click="catSelectFilterList = []"
                                                class="tgcss-w-14 tgcss-h-14 tgcss-border-l group tgcss-border-l-gray-100 dark:tgcss-border-l-[#2a2a2a] tgcss-p-[13px] ltr:tgcss-rotate-180">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                    stroke-width="1.5" stroke="currentColor"
                                                    class="w-6 h-6 tgcss-opacity-40 hover:tgcss-opacity-80 tgcss-cursor-pointer">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                        d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                </svg>
                                            </div>
                                            <input @keyup="filteredItems()" v-model="search_cat_text"
                                                class="tgcss-w-full tgcss-grow tgcss-bg-gray-50 dark:tgcss-bg-[#3c3c3c] tgcss-p-4  tgcss-text-sm tgcss-border-0 focus:tgcss-outline-none"
                                                placeholder="نام را وارد کنید ...">
                                        </div>

                                        <ul
                                            class="tgcss-search-list-items tgcss-overflow-auto tgcss-absolute tgcss-inset-0 tgcss-top-14">
                                            <li v-for="(Item, index) in catSelectFilterList" v-bind:key="index">
                                                <div v-if="Item.result == false">
                                                    <div
                                                        class="tgcss-text-center tgcss-w-full tgcss-p-5 tgcss-text-xs tgcss-opacity-50">
                                                        مقداری یافت نشد
                                                    </div>
                                                </div>
                                                <div v-else @click="setCat(Item)"
                                                    class="tgcss-group tgcss-search-list-item md:tgcss-flex tgcss-border-b tgcss-border-b-gray-100 dark:tgcss-border-b-[#2a2a2a] tgcss-items-center tgcss-flex-nowrap tgcss-px-5 tgcss-text-gray-800 tgcss-text-sm tgcss-font-medium hover:tgcss-bg-gray-100 dark:tgcss-text-[#f2f2f2]  dark:hover:tgcss-bg-[#2a2a2a]">
                                                    <div target="_blank"
                                                        class="tgcss-cursor-pointer md:tgcss-h-[45px] md:tgcss-truncate tgcss-flex tgcss-items-center tgcss-flex-grow tgcss-py-3 tgcss-font-normal">

                                                        <div v-if="Item.flag == false"
                                                            class="tgcss-w-7 rtl:tgcss-ml-1 ltr:tgcss-mr-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                                class="tgcss-w-5 ltr:tgcss-rotate-180 tgcss-h-5  tgcss-inline-block ">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                                                            </svg>
                                                        </div>
                                                        <div v-else
                                                            class="tgcss-w-8 md:tgcss-w-7 rtl:tgcss-ml-1 ltr:tgcss-mr-1 tgcss-relative tgcss-top-[2px] md:tgcss-top-[1px]">
                                                            <img v-bind:src="'https://www.tgju.org/images/flags/4x3/' + Item.slug + '.svg'"
                                                                class="tgcss-w-7 tgcss-h-7 md:tgcss-w-5 md:tgcss-h-5 -tgcss-mt-[2px]  tgcss-inline-block  tgcss-rounded-full tgcss-overflow-hidden">
                                                        </div>

                                                        <div class="tgcss-block md:tgcss-flex tgcss-grow">
                                                            <span class="tgcss-grow md:tgcss-truncate tgcss-font-medium">
                                                                {{ Item.name }}
                                                                <div
                                                                    class=" md:tgcss-flex tgcss-items-center rtl:tgcss-pl-5 ltr:tgcss-pr-5">
                                                                    <div
                                                                        class="tgcss-text-gray-600 dark:tgcss-text-gray-100 tgcss-text-xss tgcss-font-normal tgcss-whitespace-nowrap tgcss-opacity-50 tgcss-uppercase">
                                                                        {{ Item.slug }}</div>
                                                                </div>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </li>
                                        </ul>

                                    </div>
                                </div>
                            </slot>

                            <slot v-cloak v-if="showExchangeList" 
                                class="tgcss-border-b tgcss-border-gray-100 dark:tgcss-border-[#2a2a2a] tgcss-py-[10px] tgcss-leading-3">

                                <div class="tgcss-inline-block">

                                    <div v-if="showExchangeList"
                                        class="showExchangeList tgcss-absolute tgcss-inset-0 tgcss-bg-white dark:tgcss-bg-[#3c3c3c] tgcss-z-20 tgcss-top-0 tgcss-h-full ">
                                        
                                        <div class="tgcss-border-b tgcss-border-b-gray-100 dark:tgcss-border-b-[#2a2a2a] tgcss-flex">
                                            <div @click="backExchange()"
                                                class="tgcss-w-14 tgcss-h-14 tgcss-border-l group tgcss-border-l-gray-100 dark:tgcss-border-l-[#2a2a2a] tgcss-p-[13px] ltr:tgcss-rotate-180">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                    stroke-width="1.5" stroke="currentColor"
                                                    class="w-6 h-6 tgcss-opacity-40 hover:tgcss-opacity-80 tgcss-cursor-pointer">
                                                    <path stroke-linecap="round" stroke-linejoin="round"
                                                        d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                </svg>
                                            </div>
                                            <input @keyup="filteredExchangeItems(exchangeList)" v-model="search_exchange_text"
                                                class="tgcss-w-full tgcss-grow tgcss-bg-gray-50 dark:tgcss-bg-[#3c3c3c] tgcss-p-4  tgcss-text-sm tgcss-border-0 focus:tgcss-outline-none"
                                                placeholder="نام را وارد کنید ...">

                                        </div>
                                        
                                        <ul v-if="showResultSearch"
                                            class="tgcss-search-list-items tgcss-overflow-auto tgcss-absolute tgcss-inset-0 tgcss-top-14">
                                            <li v-for="(Item, index) in exchangeFilterList" v-bind:key="index">

                                                <div @click="setExchange(Item)"
                                                    class="tgcss-group tgcss-search-list-item md:tgcss-flex tgcss-border-b tgcss-border-b-gray-100 dark:tgcss-border-b-[#2a2a2a] tgcss-items-center tgcss-flex-nowrap tgcss-px-5 tgcss-text-gray-800 tgcss-text-sm tgcss-font-medium hover:tgcss-bg-gray-100 dark:tgcss-text-[#f2f2f2]  dark:hover:tgcss-bg-[#2a2a2a]">
                                                    <div target="_blank"
                                                        class="tgcss-cursor-pointer md:tgcss-h-[45px] md:tgcss-truncate tgcss-flex tgcss-items-center tgcss-flex-grow tgcss-py-3 tgcss-font-normal">


                                                        <div class="tgcss-block md:tgcss-flex tgcss-grow">
                                                            <span class="tgcss-grow md:tgcss-truncate tgcss-font-medium">
                                                                {{ Item.title }}
                                                                <div
                                                                    class=" md:tgcss-flex tgcss-items-center rtl:tgcss-pl-5 ltr:tgcss-pr-5">
                                                                    <div
                                                                        class="tgcss-text-gray-600 dark:tgcss-text-gray-100 tgcss-text-xss tgcss-font-normal tgcss-whitespace-nowrap tgcss-opacity-50 tgcss-uppercase">
                                                                        {{ Item.title }}</div>
                                                                </div>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                        <ul v-else
                                            class="tgcss-search-list-items tgcss-overflow-auto tgcss-absolute tgcss-inset-0 tgcss-top-14">
                                            <div v-if="awaiting_reply">
                                                <div
                                                    class="tgcss-text-center tgcss-w-full tgcss-p-5 tgcss-text-xs tgcss-opacity-50">
                                                    پاسخ تا لحظاتی دیگر دریافت خواهد شد
                                                </div>
                                            </div>

                                            <div v-if="exchangeList.length==0 && show_null_result">
                                                <div
                                                    class="tgcss-text-center tgcss-w-full tgcss-p-5 tgcss-text-xs tgcss-opacity-50">
                                                    مقداری یافت نشد
                                                </div>
                                            </div>

                                            <li v-for="(Item, index) in exchangeList" v-bind:key="index">

                                                <div @click="setExchange(Item)"
                                                    class="tgcss-group tgcss-search-list-item md:tgcss-flex tgcss-border-b tgcss-border-b-gray-100 dark:tgcss-border-b-[#2a2a2a] tgcss-items-center tgcss-flex-nowrap tgcss-px-5 tgcss-text-gray-800 tgcss-text-sm tgcss-font-medium hover:tgcss-bg-gray-100 dark:tgcss-text-[#f2f2f2]  dark:hover:tgcss-bg-[#2a2a2a]">
                                                    <div target="_blank"
                                                        class="tgcss-cursor-pointer md:tgcss-h-[45px] md:tgcss-truncate tgcss-flex tgcss-items-center tgcss-flex-grow tgcss-py-3 tgcss-font-normal">


                                                        <div class="tgcss-block md:tgcss-flex tgcss-grow">
                                                            <span class="tgcss-grow md:tgcss-truncate tgcss-font-medium">
                                                                {{ Item.title }}
                                                                <div
                                                                    class=" md:tgcss-flex tgcss-items-center rtl:tgcss-pl-5 ltr:tgcss-pr-5">
                                                                    <div
                                                                        class="tgcss-text-gray-600 dark:tgcss-text-gray-100 tgcss-text-xss tgcss-font-normal tgcss-whitespace-nowrap tgcss-opacity-50 tgcss-uppercase">
                                                                        {{ Item.title }}</div>
                                                                </div>
                                                            </span>
                                                        </div>

                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </slot>

                            <div v-if="search_loading == true"
                                class="tgcss-p-12 tgcss-pt-5 tgcss-flex tgcss-justify-center">
                                <div role="status">
                                    <svg aria-hidden="true"
                                        class="tgcss-w-10 tgcss-h-10 tgcss-mr-2 tgcss-text-gray-200 tgcss-animate-spin tgcss-fill-gray-500"
                                        viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor" />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill" />
                                    </svg>
                                </div>
                            </div>
                            <div v-if="search_loading == false" class="tgcss-grow tgcss-overflow-auto tgcss-mt-2"
                                id="scrollLoader-content" @vue:mounted="scrollLoader">
                                <ul class="tgcss-search-list-items news-ss">

                                    <!-- <div v-if="active_header.title == 'اخبار'" class="tgcss-hidden  md:tgcss-grid tgcss-grid-cols-5 tgcss-gap-3 tgcss-p-4">
                                        <div v-for="(item, index) in data.slice(0,5)" v-bind:key="index" class="tgcss-border tgcss-border-gray-200 dark:tgcss-border-[#2a2a2a] tgcss-rounded-lg tgcss-overflow-hidden tgcss-cursor-pointer tgcss-pb-[15px]" @click="render[item.type].action(render[item.type].url(item))">
                                            <div class="tgcss-h-28 tgcss-bg-cover tgcss-bg-center tgcss-bg-no-repeat" v-bind:style="{ backgroundImage: 'url(' + item.image + ')' }"></div>
                                            <div class="tgcss-text-sm tgcss-font-normal tgcss-p-4 tgcss-leading-5 tgcss-line-clamp-2 tgcss-h-14 tgcss-overflow-hidden">{{item.title}}</div>
                                        </div>
                                    </div> -->

                                    <div v-if="active_header.title == 'ابزارها' && search_text.length == 0 && def == true"
                                        class="tgcss-hidden  md:tgcss-grid tgcss-grid-cols-5 tgcss-gap-3 tgcss-p-4">
                                        <div v-for="(item, index) in data" v-bind:key="index">
                                            <div class="tgcss-border tgcss-border-gray-200 dark:tgcss-border-[#2a2a2a] tgcss-rounded-lg tgcss-overflow-hidden tgcss-cursor-pointer tgcss-text-center tgcss-px-4 tgcss-py-5"
                                                @click="setCatTools(item)">
                                                <img class="tgcss-w-[80px] tgcss-block tgcss-m-auto  tgcss-mb-3"
                                                    :src="item.img">
                                                <div class="tgcss-text-sm tgcss-font-medium">{{ item.title }}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <slot v-else>
                                        <li v-for="(item, index) in data" v-bind:key="index">
                                            <div v-if="render[item.type]"
                                                v-bind:class="'index' + index + ' tgcss-group tgcss-search-list-item md:tgcss-flex tgcss-border-b tgcss-border-b-gray-100 dark:tgcss-border-b-[#2a2a2a] tgcss-items-center tgcss-flex-nowrap tgcss-px-5 tgcss-text-gray-800 tgcss-text-sm tgcss-font-medium hover:tgcss-bg-gray-100 dark:tgcss-text-[#f2f2f2]  dark:hover:tgcss-bg-[#2a2a2a]'">
                                                <div target="_blank"
                                                    class="tgcss-relative tgcss-cursor-pointer md:tgcss-h-[45px] md:tgcss-truncate tgcss-flex tgcss-items-center tgcss-flex-grow tgcss-py-3 tgcss-font-normal">
                                                    <div @click="render[item.type].action(render[item.type].url(item))"
                                                        class="tgcss-inset-0 tgcss-absolute tgcss-z-10"></div>
                                                    <div v-if="render[item.type].tags(item)"
                                                        @click="render[item.type].action(render[item.type].url(item))"
                                                        class="tgcss-inset-0 tgcss-absolute tgcss-z-20 md:tgcss-hidden">
                                                    </div>

                                                    <slot
                                                        v-if="typeof render[item.type].icon !== 'undefined' && render[item.type].icon(item) !== undefined">
                                                        <div
                                                            class="tgcss-w-8 md:tgcss-w-7 rtl:tgcss-ml-1 ltr:tgcss-mr-1 tgcss-relative tgcss-top-[2px] md:tgcss-top-[1px]">
                                                            <img :src="render[item.type].icon(item)"
                                                                class="tgcss-w-7 tgcss-h-7 md:tgcss-w-5 md:tgcss-h-5 -tgcss-mt-[2px]  tgcss-inline-block  tgcss-rounded-full tgcss-overflow-hidden">
                                                        </div>
                                                    </slot>

                                                    <slot
                                                        v-else-if="typeof render[item.type].flag !== 'undefined' && render[item.type].flag(item) !== undefined">
                                                        <div
                                                            class="tgcss-w-8 md:tgcss-w-7 rtl:tgcss-ml-1 ltr:tgcss-mr-1 tgcss-relative tgcss-top-[2px] md:tgcss-top-[1px]">
                                                            <img :src="render[item.type].flag(item)"
                                                                class="tgcss-w-7 tgcss-h-7 md:tgcss-w-5 md:tgcss-h-5 -tgcss-mt-[2px]  tgcss-inline-block  tgcss-rounded-full tgcss-overflow-hidden">
                                                        </div>
                                                    </slot>

                                                    <slot v-else>
                                                        <div class="tgcss-w-7 rtl:tgcss-ml-1 ltr:tgcss-mr-1">
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none"
                                                                viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                                                                class="tgcss-w-5 ltr:tgcss-rotate-180 tgcss-h-5  tgcss-inline-block ">
                                                                <path stroke-linecap="round" stroke-linejoin="round"
                                                                    d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75" />
                                                            </svg>
                                                        </div>
                                                    </slot>

                                                    <div class="tgcss-block md:tgcss-flex tgcss-grow">

                                                        <span class="tgcss-grow md:tgcss-truncate tgcss-font-medium">
                                                            {{ render[item.type].title(item) }}
                                                            <div v-if="typeof render[item.type].market !== 'undefined'"
                                                                class=" md:tgcss-flex tgcss-items-center rtl:tgcss-pl-5 ltr:tgcss-pr-5">
                                                                <div
                                                                    class="tgcss-text-gray-600 dark:tgcss-text-gray-100 tgcss-text-xss tgcss-font-normal tgcss-whitespace-nowrap tgcss-opacity-50 tgcss-leading-4">
                                                                    {{ render[item.type].market() }}</div>
                                                            </div>
                                                        </span>


                                                        <div v-if="typeof render[item.type].category !== 'undefined'"
                                                            class=" md:tgcss-flex tgcss-items-center rtl:tgcss-pl-10 ltr:tgcss-pr-10">
                                                            <div
                                                                class="tgcss-text-gray-600 dark:tgcss-text-gray-100 tgcss-text-xss tgcss-font-normal tgcss-whitespace-nowrap">
                                                                {{ render[item.type].category(item) }}</div>
                                                        </div>

                                                    </div>


                                                    <div v-if="item.type == 'solutions.farsi'"
                                                        class="tgcss-text-gray-600 dark:tgcss-text-gray-100 tgcss-text-xs tgcss-font-normal tgcss-whitespace-nowrap tgcss-opacity-50 tgcss-hidden md:tgcss-flex">
                                                        {{ item.category.replace(/^(.*?). /i, "") }}
                                                    </div>


                                                    <div v-if="item.type == 'tools' || item.type == 'technical'"
                                                        class="tgcss-text-gray-600 dark:tgcss-text-gray-100 dark:tgcss-bg-gray-400 tgcss-text-xs tgcss-font-normal tgcss-whitespace-nowrap tgcss-opacity-50 tgcss-hidden md:tgcss-flex">
                                                        {{ item.category }}
                                                    </div>


                                                    <!-- <div style="min-width: 200px;text-align: left;justify-content: left;" v-if="item.type == 'news' || item.type == 'technical'" class="tgcss-text-gray-600 dark:tgcss-text-gray-100 tgcss-text-xs tgcss-font-normal tgcss-whitespace-nowrap tgcss-opacity-50 tgcss-hidden md:tgcss-flex">
                                                        {{item.gcreated_at}}
                                                    </div>  -->


                                                    <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                                                        stroke-width="1.5" stroke="currentColor"
                                                        class="tgcss-w-6 tgcss-h-6 md:tgcss-hidden tgcss-opacity-30">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            d="M15.75 19.5L8.25 12l7.5-7.5" />
                                                    </svg>-->

                                                </div>
                                                <div
                                                    class=" tgcss-flex tgcss-items-center tgcss-pb-3 md:tgcss-pb-0 md:tgcss-pt-0 rtl:md:tgcss-mr-0  ltr:md:tgcss-ml-0 tgcss-search-list-item-actions">
                                                    <div v-for="(tag, index) in render[item.type].tags(item)"
                                                        v-bind:key="index" class="tgcss-flex-grow md:tgcss-flex-grow-0">
                                                        <div @click="render[item.type].action(tag.url)" target="_blank"
                                                            class="tgcss-bg-white tgcss-cursor-pointer tgcss-text-center dark:tgcss-text-white dark:tgcss-bg-[#2a2a2a] dark:tgcss-border-[#2a2a2a] hover:dark:tgcss-bg-[#636363] hover:dark:tgcss-border-[#636363] hover:tgcss-bg-gray-800 hover:tgcss-border-gray-800 hover:tgcss-text-white tgcss-z-10 tgcss-py-1 tgcss-px-2 rtl:tgcss-mr-2 ltr:tgcss-ml-2 tgcss-text-xss tgcss-rounded-md tgcss-border tgcss-text-gray-600  tgcss-whitespace-nowrap">
                                                            {{ tag.title }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    </slot>

                                </ul>
                                <div v-if="last == true"
                                    class="tgcss-text-center tgcss-w-full tgcss-p-5 tgcss-text-xs tgcss-opacity-50">
                                    لیست به صورت کامل بارگذاری شد
                                </div>
                                <div v-if="scroll_Loading == true"
                                    class="tgcss-p-12 tgcss-pt-5 tgcss-flex tgcss-justify-center">
                                    <div role="status">
                                        <svg aria-hidden="true"
                                            class="tgcss-w-6 tgcss-h-6 tgcss-mr-2 tgcss-text-gray-200 tgcss-animate-spin tgcss-fill-gray-500"
                                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                                fill="currentColor" />
                                            <path
                                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                                fill="currentFill" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tgcss-flex-grow tgcss-search-btn" slot @click="hideSearch"></div>
                    </div>
                </div>
            </div>
            <div class="tgcss-bg-gray-100"></div>
        </div>

    </div>
</template>

<style lang="scss">
#main-header {
    z-index: 100000000;
}

#bug-report-modal,
.modal[role="dialog"] {
    z-index: 999999999;
}

#indicator-modal {
    z-index: 2147483647;
}
</style>

<script>
// این کامپوننت مودال افزودن آیتم به واچ لیست مباشد
// در روش قبلی از شاخص یاب برای افزودن واچ لیست ها استفاده می شد با تغییرات انجام شده در این کامپوننت از سرچی که در سایت اصلی وجود دارد استفاده شد 
// البته تغییراتی در آن انجام شد 
export default {
    name: 'WatchListAdd',
    props: ['data'],
    components: {},
    data: function () {
        var that = this;
        return {
            indicator: undefined,
            // از اینجا مربوط به استفاده از سرچ برای اضافه کردن ویجت
            config:  [{
                lang: 'fa',
                client: 'tgju', // tgju, shakhesban, marketban, app, ...
                header: [
                    // {
                    //     title: "همه",
                    //     sections: [
                    //         {
                    //             title: "همه",
                    //             source: "https://api.tgju.org/v1/newsearch?module=all",
                    //         },
                    //     ],
                    // },
                    {
                        title: "بازار داخلی",
                        sections: [
                            {
                                title: "همه",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.internal",
                            },
                            {
                                title: "طلا",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.internal&batch=internal_gold",
                            },
                            {
                                title: "سکه",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.internal&batch=internal_coin",
                            },
                            {
                                title: "ارز",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.internal&batch=internal_currency",
                            },
                            // {
                            //     title: "بورس",
                            //     source: "https://api.tgju.org/v1/newsearch?module=markets.internal.index",
                            // },
                            // {
                            //     title: "سهام",
                            //     source: "https://api.tgju.org/v1/newsearch?module=markets.internal&batch=internal_stock",
                            // },
                            // {
                            //     title: "اوراق",
                            //     source: "https://api.tgju.org/v1/newsearch?module=markets.internal&batch=internal_bonds",
                            // },
                            // {
                            //     title: "آتی",
                            //     source: "https://api.tgju.org/v1/newsearch?module=markets.internal&batch=internal_future",
                            // },
                            // {
                            //     title: "آبشن",
                            //     source: "https://api.tgju.org/v1/newsearch?module=markets.internal&batch=internal_option",
                            // },
                            // {
                            //     title: "صندوق‌ها",
                            //     source: "https://api.tgju.org/v1/newsearch?module=markets.internal&batch=internal_fund",
                            // },
                            {
                                title: "بازار ارز دیجیتال",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global&batch=crypto",
                            },
                        ],
                    },
                    {
                        title: "بازار جهانی",
                        sections: [
                            {
                                title: "همه",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global",
                            },
                            {
                                title: "فلزات گرانبها",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global&batch=global_precious_metals",
                            },
                            {
                                title: "فلزات پایه",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global&batch=global_base_metals",
                            },
                            {
                                title: "انرژی",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global&batch=global_energy",
                            },
                            {
                                title: "کالایی",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global&batch=global_commodity",
                            },
                            {
                                title: "اوراق قرضه",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global&batch=global_bonds",
                            },
                            {
                                title: "بازارهای ارزی",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global&batch=global_currencies",
                            },
                            {
                                title: "بورس",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global&batch=indices_bourse_countries",
                            },
                            {
                                title: "سهام",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global&batch=global_stocks",
                            },
                            {
                                title: "صندوق‌ها",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global&batch=global_fund",
                            },
                            {
                                title: "ارز دیجیتال",
                                source: "https://api.tgju.org/v1/newsearch?module=markets.global&batch=crypto",
                            },
                        ],
                    },
                    // {
                    //     title: "شاخص‌ها",
                    //     sections: [
                    //         {
                    //             title: "همه",
                    //             source: "https://api.tgju.org/v1/newsearch?module=economics",
                    //         },
                    //         {
                    //             title: "اقتصاد کشورها",
                    //             source: "https://api.tgju.org/v1/newsearch?module=economics&batch=economics_country",
                    //         },
                    //         {
                    //             title: "پیش‌بینی اقتصاد کشورها",
                    //             source: "https://api.tgju.org/v1/newsearch?module=economics&batch=economics_forecast_countries",
                    //         },
                    //         {
                    //             title: "شاخص‌ها",
                    //             source: "https://api.tgju.org/v1/newsearch?module=economics&batch=economics_index",
                    //         },
                    //         {
                    //             title: "پیش‌بینی شاخص‌های اقتصادی",
                    //             source: "https://api.tgju.org/v1/newsearch?module=economics&batch=economics_forecast_indicators",
                    //         },
                    //     ],
                    // },
                    {
                        title: "صرافی‌ها",
                        sections: [
                            {
                                title: "همه",
                                source: "https://api.tgju.org/v1/newsearch?module=exchange",
                            },
                            {
                                title: "صرافی‌های ارزی داخلی",
                                source: "https://api.tgju.org/v1/newsearch?module=exchange&batch=exchange_currency_internal",
                            },
                            {
                                title: "صرافی‌های کریپتو داخلی",
                                source: "https://api.tgju.org/v1/newsearch?module=exchange&batch=exchange_crypto_internal",
                            },
                            {
                                title: "صرافی‌های کریپتو جهانی",
                                source: "https://api.tgju.org/v1/newsearch?module=exchange&batch=exchange_global",
                            },
                        ],
                    },
                    // {
                    //     title: "تکنیکال",
                    //     sections: [
                    //         {
                    //             title: "همه",
                    //             source: "https://api.tgju.org/v1/newsearch?module=technical",
                    //         },
                    //     ],
                    // },
                    // {
                    //     title: "اخبار",
                    //     sections: [
                    //         {
                    //             title: "اخبار ویژه",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=special_news",
                    //         },
                    //         {
                    //             title: "طلا وسکه",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_gold",
                    //         },
                    //         {
                    //             title: "ارزی",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_currency",
                    //         },
                    //         {
                    //             title: "ارزهای دیجیتال",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_digital_currency",
                    //         },
                    //         {
                    //             title: "بورس",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_bourse",
                    //         },
                    //         {
                    //             title: "نفت و انرژی",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_energy",
                    //         },
                    //         {
                    //             title: "خودرو",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_car",
                    //         },
                    //         {
                    //             title: "فناوری",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_technology",
                    //         },
                    //         {
                    //             title: "مسکن",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_housing",
                    //         },
                    //         {
                    //             title: "تولید و تجارت",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_production",
                    //         },
                    //         {
                    //             title: "بانکی",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_bank",
                    //         },
                    //         {
                    //             title: "جهان",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_world",
                    //         },
                    //         {
                    //             title: "قیمت‌ها",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=news_price",
                    //         },
                    //         {
                    //             title: "گزارش بازارها",
                    //             source: "https://api.tgju.org/v1/newsearch?module=news&batch=market_report",
                    //         },
                    //     ],
                    // },
                    // {
                    //     title: "راهنما",
                    //     sections: [
                    //         {
                    //             title: "عمومی",
                    //             source: "https://api.tgju.org/v1/newsearch?module=solutions.farsi&batch=solutions_a",
                    //         },
                    //         {
                    //             title: "سامانه‌ها",
                    //             source: "https://api.tgju.org/v1/newsearch?module=solutions.farsi&batch=solutions_b",
                    //         },
                    //         {
                    //             title: "ابزارها",
                    //             source: "https://api.tgju.org/v1/newsearch?module=solutions.farsi&batch=solutions_c",
                    //         },
                    //         {
                    //             title: "پروفایل‌ها",
                    //             source: "https://api.tgju.org/v1/newsearch?module=solutions.farsi&batch=solutions_d",
                    //         },
                    //         {
                    //             title: "پنل کاربری",
                    //             source: "https://api.tgju.org/v1/newsearch?module=solutions.farsi&batch=solutions_e",
                    //         },
                    //         {
                    //             title: "مفاهیم پایه",
                    //             source: "https://api.tgju.org/v1/newsearch?module=solutions.farsi&batch=solutions_f",
                    //         },
                    //         {
                    //             title: "مفاهیم تحلیلی",
                    //             source: "https://api.tgju.org/v1/newsearch?module=solutions.farsi&batch=solutions_g",
                    //         },
                    //         {
                    //             title: "مفاهیم معاملاتی",
                    //             source: "https://api.tgju.org/v1/newsearch?module=solutions.farsi&batch=solutions_h",
                    //         },
                    //         {
                    //             title: "مفاهیم پرداخت‌ارزی",
                    //             source: "https://api.tgju.org/v1/newsearch?module=solutions.farsi&batch=solutions_i",
                    //         },
                    //     ],
                    // },
                    // {
                    //     title: "ابزارها",
                    //     sections: [
                    //         {
                    //             title: "همه",
                    //             source: "https://api.tgju.org/v1/newsearch?module=tools",
                    //         },
                    //     ],
                    // },
                ],
                render: {
                    "markets.internal": {
                        market: function(row) {
                            return 'بازار داخلی';
                        },
                        url: function(row) {
                            return row;
                        },
                        icon: function(row) {
                            if (typeof(row.image) !== 'undefined' && row.image !== null && row.image !== '') {
                                return `https://platform.tgju.org/files/images/${row.image}`;
                            } else if (typeof(row.s4) !== 'undefined' && row.s4 !== null && row.s4 !== '') {
                                return `https://www.tgju.org/images/flags/4x3/${row.s4}.svg`;
                            } else {
                                return undefined;
                            }
                        },
                        title: function(row) {
                            return row.title;
                        },
                        category: function(row) {

                            if (window.screen.width > 800) {
                                return row.category;
                            }
                        },
                        tags: function(row) {
                            //return [
                                // {title: 'پروفایل', url: `/market/profile/${row.n1}/overview`},
                                // {title: 'تاریخچه', url: `/market/profile/${row.n1}/history`},
                                // {title: 'تکنیکال', url: `/market/profile/${row.n1}/technical`},
                                // {title: 'نمودار', url: `/market/profile/${row.n1}/charts`},
                            //];
                        },
                        action: function(row) {
                            
                            that.indicator=row
                            that.hideSearch();
                        },
                    },
                    // "markets.internal.stocks": {
                    //     market: function(row) {
                    //         return 'بورس';
                    //     },
                    //     url: function(row) {
                    //         return row;
                    //     },
                    //     icon: function(row) {
        
        
                    //         if (typeof(row.title_en) == 'undefined' || row.title_en == null || row.title_en == '') {
                    //             return `https://static.tgju.org/views/default/images/shakhesban/shakhesban-logo.jpg?v=6`;
                    //         } else if (row.s2 =='bond' || row.s2 =='future'|| row.s2 =='option'|| row.s2 =='fund') {
                    //             return `https://static.tgju.org/views/default/images/shakhesban/shakhesban-logo.jpg?v=6`;
                    //         } else if (typeof(row.grade) !== 'undefined' && row.grade !== null && row.grade !== '') {
                    //             return `https://serviceprovider.tgju.org/files/images/stocks/instrument-icons/${row.grade}.jpg`;
                    //         } else {
                    //             return undefined;
                    //         }
                            
                    //     },
                    //     title: function(row) {
                    //         return row.title;
                    //     },
                    //     category: function(row) {
                    //         if (window.screen.width > 800) {
                    //             return row.category;
                    //         }
                    //     },
                    //     tags: function(row) {
                    //         //return [
                    //             // {title: 'پروفایل', url: `/market/stock/profile/${row.grade}/overview`},
                    //             // {title: 'تاریخچه', url: `/market/stock/profile/${row.grade}/history`},
                    //             // {title: 'تکنیکال', url: `/market/stock/profile/${row.grade}/technical`},
                    //             // {title: 'نمودار', url: `/market/stock/profile/${row.grade}/charts`},
                    //         //];
                    //     },
                    //     action: function(row) {
                    //         that.indicator=row
                    //         that.hideSearch();
                    //     },
                    // },
                    // "markets.internal.index": {
                    //     market: function(row) {
                    //         return 'بازار داخلی';
                    //     },
                    //     url: function(row) {
                    //         return row;
                    //     },
                    //     icon: function(row) {
                    //         if (typeof(row.title_en) == 'undefined' || row.title_en == null || row.title_en == '') {
                    //             return `https://static.tgju.org/views/default/images/shakhesban/shakhesban-logo.jpg?v=6`;
                    //         } else if (row.s2 =='bond' || row.s2 =='future'|| row.s2 =='option'|| row.s2 =='fund') {
                    //             return `https://static.tgju.org/views/default/images/shakhesban/shakhesban-logo.jpg?v=6`;
                    //         } else if (typeof(row.grade) !== 'undefined' && row.grade !== null && row.grade !== '') {
                    //             return `https://serviceprovider.tgju.org/files/images/stocks/instrument-icons/${row.grade}.jpg`;
                    //         } else {
                    //             return undefined;
                    //         }
                    //     },
                    //     title: function(row) {
                    //         return row.title;
                    //     },
                    //     category: function(row) {
                    //         if (window.screen.width > 800) {
                    //             return row.category;
                    //         }
                    //     },
                    //     tags: function(row) {
                    //         // return [
                    //         //     {title: 'پروفایل', url: `/market/index/profile/${row.s1}/overview`},
                    //         //     {title: 'تاریخچه', url: `/market/index/profile/${row.s1}/history`},
                    //         //     {title: 'تکنیکال', url: `/market/index/profile/${row.s1}/technical`},
                    //         //     {title: 'نمودار', url: `/market/index/profile/${row.s1}/charts`},
                    //         // ];
                    //     },
                    //     action: function(row) {
                            
                    //         row.n1=row.s1
                    //         that.indicator=row
                    //         that.hideSearch();
                    //     },
                    // },
                    "markets.global": {
                        market: function(row) {
                            return 'بازار جهانی';
                        },
                        url: function(row) {
                            return row;
                        },
                        icon: function(row) {
                            
                            if (typeof(row.image) !== 'undefined' && row.image !== null && row.image !== '') {
                                return `https://platform.tgju.org/files/images/${row.image}`;
                            } else if (typeof(row.s4) !== 'undefined' && row.s4 !== null && row.s4 !== '') {
                                return `https://www.tgju.org/images/flags/4x3/${row.s4}.svg`;
                            } else if (typeof(row.s5) !== 'undefined' && row.s5 !== null && row.s5 !== '') {
                                return `https://www.tgju.org/images/flags/4x3/${row.s5.toLowerCase()}.svg`;
                            } else {
                                return undefined;
                            }                
                        },
                        title: function(row) {
                            return row.title;
                        },
                        category: function(row) {
                            if (window.screen.width > 800) {
                                return row.category;
                            }
                        },
                        tags: function(row) {
                            // return [
                            //     {title: 'پروفایل', url: `/market/profile/${row.n1}/overview`},
                            //     {title: 'تاریخچه', url: `/market/profile/${row.n1}/history`},
                            //     {title: 'تکنیکال', url: `/market/profile/${row.n1}/technical`},
                            //     {title: 'نمودار', url: `/market/profile/${row.n1}/charts`},
                            // ];
                        },
                        action: function(row) {
                            that.indicator=row
                            that.hideSearch();
                        },
                    },
                    // "markets.retail": {
                    //     market: function(row) {
                    //         return 'بازار خرده فروشی';
                    //     },
                    //     url: function(row) {
                    //         return `https://marketban.com/show/product/${row.mid}/${row.title}`;
                    //     },
                    //     icon: function(row) {
                    //         return `https://static.tgju.org/views/default/images/marketban-s.png`;
                    //     },
                    //     title: function(row) {
                    //         return row.title;
                    //     },
                    //     category: function(row) {
                    //         if (window.screen.width > 800) {
                    //             return row.category;
                    //         }
                    //     },
                    //     tags: function(row) {
                    //         // return [
                    //         //     {title: 'مشاهده', url: `https://marketban.com/show/product/${row.mid}/${row.title}`},
                    //         // ];
                    //     },
                    //     action: function(url) {
                    //         that.$router.push({ path: url });
                    //         that.hideSearch();
                    //     },
                    // },
                    // "markets.retail.paper": {
                    //     market: function(row) {
                    //         return 'بازار کاغذ';
                    //     },
                    //     url: function(row) {
                    //         return `https://marketban.com/show/product/${row.mid}/${row.title}`;
                    //     },
                    //     icon: function(row) {
                    //         return `https://static.tgju.org/views/default/images/marketban-s.png`;
                    //     },
                    //     title: function(row) {
                    //         return row.title;
                    //     },
                    //     category: function(row) {
                    //         if (window.screen.width > 800) {
                    //             return row.category;
                    //         }
                    //     },
                    //     tags: function(row) {
                    //         // return [
                    //         //     {title: 'مشاهده', url: `https://marketban.com/show/product/${row.mid}/${row.title}`},
                    //         // ];
                    //     },
                    //     action: function(url) {
                    //         that.$router.push({ path: url });
                    //         that.hideSearch();
                    //     },
                    // },
                    // "economics.forecast": {
                    //     market: function(row) {
                    //         return 'شاخص‌ها';
                    //     },
                    //     url: function(row) {
                    //         return row;
                    //     },
                    //     icon: function(row) {
                    //         if (typeof(row.image) !== 'undefined' && row.image !== null && row.image !== '') {
                    //             return `https://platform.tgju.org/files/images/${row.image}`;
                    //         } else if (typeof(row.s4) !== 'undefined' && row.s4 !== null && row.s4 !== '') {
                    //             return `https://www.tgju.org/images/flags/4x3/${row.s4}.svg`;
                    //         } else {
                    //             return undefined;
                    //         }
                    //     },
                    //     title: function(row) {
                    //         return row.title;
                    //     },
                    //     category: function(row) {
                    //         if (window.screen.width > 800) {
                    //             return row.category;
                    //         }
                    //     },
                    //     tags: function(row) {
                    //         // return [
                    //         //     {title: 'مشاهده', url: `/economics/atlas/${row.s4}`},
                    //         // ];
                    //     },
                    //     action: function(row) {
                    //         row.n1=row.s1
                            
                    //         that.indicator=row
                    //         that.hideSearch();
                    //     },
                    // },
                    // "economics.country": {
                    //     market: function(row) {
                    //         return 'شاخص‌ها';
                    //     },
                    //     url: function(row) {
                    //         return row;
                    //     },
                    //     icon: function(row) {
                    //         if (typeof(row.image) !== 'undefined' && row.image !== null && row.image !== '') {
                    //             return `https://platform.tgju.org/files/images/${row.image}`;
                    //         } else if (typeof(row.s4) !== 'undefined' && row.s4 !== null && row.s4 !== '') {
                    //             return `https://www.tgju.org/images/flags/4x3/${row.s4}.svg`;
                    //         } else {
                    //             return undefined;
                    //         }                    
                    //     },
                    //     title: function(row) {
                    //         return row.title;
                    //     },
                    //     category: function(row) {
                    //         if (window.screen.width > 800) {
                    //             return row.category;
                    //         }
                            
                    //     },
                    //     tags: function(row) {
                    //         // return [
                    //         //     {title: 'مشاهده', url: `/economics/atlas/${row.s4}`},
                    //         // ];
                    //     },
                    //     action: function(row) {
                    //         row.n1=row.s1
                            
                    //         that.indicator=row
                    //         that.hideSearch();
                    //     },
                    // },
                    // "economics.index": {
                    //     market: function(row) {
                    //         return 'شاخص‌ها';
                    //     },
                    //     url: function(row) {
                    //         return row;
                    //     },
                    //     icon: function(row) {
                    //         if (typeof(row.image) !== 'undefined' && row.image !== null && row.image !== '') {
                    //             return `https://platform.tgju.org/files/images/${row.image}`;
                    //         } else if (typeof(row.s4) !== 'undefined' && row.s4 !== null && row.s4 !== '') {
                    //             return `https://www.tgju.org/images/flags/4x3/${row.s4}.svg`;
                    //         } else {
                    //             return undefined;
                    //         } 
                    //     },
                    //     title: function(row) {
                    //         return row.title;
                    //     },
                    //     category: function(row) {
                    //         if (window.screen.width > 800) {
                    //             return row.category;
                    //         }
                            
                    //     },
                    //     tags: function(row) {
                    //         // return [
                    //         //     {title: 'مشاهده', url: `/economics/atlas/${row.s4}`},
                    //         // ];
                    //     },
                    //     action: function(row) {
                    //         row.n1=row.s1
                            
                    //         that.indicator=row
                    //         that.hideSearch();
                    //     },
                    // },
                    "exchange": {
                        market: function(row) {
                            return 'صرافی‌ها';
                        },
                        url: function(row) {
                            return row
                            
                        },
                        icon: function(row) {
                            return `https://static.tgju.org/views/default/images/exchanges-logo/${row.s1}.png?v=6`;
                        },
                        title: function(row) {
                            return row.title;
                        },
                        category: function(row) {
                            if (window.screen.width > 800) {
                                return row.category;
                            }
                        },
                        tags: function(row) {
                            // if(row.batch == "exchange_crypto_internal"){
                            //     return [
                            //         {title: 'پروفایل', url: `/exchanges/local/${row.mid}/${row.s1}`},
                            //     ];
                            // } else if (row.batch == "exchange_global") {
                            //     return [
                            //         {title: 'پروفایل', url: `/exchanges/global/${row.mid}/${row.s1}`},
                            //     ];
                            // }else{ 
                            //     return [
                            //         {title: 'پروفایل', url: `/exchanges/currency-exchange/${row.mid}/${row.s1}`},
                            //     ];
                            // }
                        },
                        action: function(row) {
                            row.n1=row.s1
                            
                            console.log('action');
                            that.showExchangeList=true;
                            that.getExchangeList(row.mid);
                            // that.indicator=row
                            // that.hideSearch();
                        },
                    },
                    "markets.global.crypto": {
                        market: function(row) {
                            return 'بازار ارزهای دیجیتال';
                        },
                        url: function(row) {
                            return row;
                        },
                        icon: function(row) {
                            
                            if (typeof(row.s1) !== 'undefined' && row.s1 !== null && row.s1 !== '') {
                                var u = row.s1.replace("crypto-", "")+'_'+row.grade.toLowerCase();
                            return `https://static.tgju.org/images/crypto/icons/${u}.png`;
                            }else if (typeof(row.image) !== 'undefined' && row.image !== null && row.image !== '') {
                                return `https://platform.tgju.org/files/images/${row.image}`;
                            } else if (typeof(row.s4) !== 'undefined' && row.s4 !== null && row.s4 !== '') {
                                return `https://www.tgju.org/images/flags/4x3/${row.s4}.svg`;
                            } else {
                                return undefined;
                            }
                        
                        },
                        title: function(row) {
                            return row.title;
                        },
                        category: function(row) {
                            if (window.screen.width > 800) {
                                return row.category;
                            }
                        },
                        tags: function(row) {
                            //return [
                                // {title: 'پروفایل', url: `/market/profile/${row.n1}/overview`},
                                // {title: 'تاریخچه', url: `/market/profile/${row.n1}/history`},
                                // {title: 'تکنیکال', url: `/market/profile/${row.n1}/technical`},
                                // {title: 'نمودار', url: `/market/profile/${row.n1}/charts`},
                            //];
                        },
                        action: function(row) {
                            
                            that.indicator=row
                            that.hideSearch();
                        },
                    },
                    "markets.internal.crypto": {
                        market: function(row) {
                            return 'بازار ارزهای دیجیتال';
                        },
                        url: function(row) {
                            return row;
                        },
                        icon: function(row) {
                            
                            if (typeof(row.s1) !== 'undefined' && row.s1 !== null && row.s1 !== '') {
                                var u = row.s1.replace("crypto-", "")+'_'+row.s4.toLowerCase();
                                u = u.replace("-irr", "");
                            return `https://static.tgju.org/images/crypto/icons/${u}.png`;
                            }else if (typeof(row.image) !== 'undefined' && row.image !== null && row.image !== '') {
                                return `https://platform.tgju.org/files/images/${row.image}`;
                            } else if (typeof(row.s4) !== 'undefined' && row.s4 !== null && row.s4 !== '') {
                                return `https://www.tgju.org/images/flags/4x3/${row.s4}.svg`;
                            } else {
                                return undefined;
                            }
                        
                        },
                        title: function(row) {
                            return row.title;
                        },
                        category: function(row) {
                            if (window.screen.width > 800) {
                                return row.category;
                            }
                        },
                        tags: function(row) {
                            //return [
                                // {title: 'پروفایل', url: `/profile/${row.s1.replace("-irr", "")}`},
                                // {title: 'تاریخچه', url: `/profile/${row.s1.replace("-irr", "")}/history`},
                                // {title: 'تکنیکال', url: `/profile/${row.s1.replace("-irr", "")}/technical`},
                                // {title: 'نمودار', url: `/profile/${row.s1.replace("-irr", "")}/performance`},
                            //];
                        },
                        action: function(url) {
                            
                            that.indicator=row
                            that.hideSearch();
                        },
                    },
                    // "solutions.farsi": {
                    //     url: function(row) {
                    //         return `/support/solutions/articles/${row.s1}`;
                    //     },
                    //     title: function(row) {
                    //         var t = row.title.replace(/^(.*?). /i, "");
                    //         t = t.replace("- ", "")
                    //         return t.replace(". ", "");
                    //     },
                    //     tags: function(row) {},
                    //     action: function(url) {
                    //         that.$router.push({ path: url });
                    //         that.hideSearch();
                    //     },
                    // },
                    // "solutions.english": {
                    //     url: function(row) {
                    //         return `/support/solutions/articles/${row.s1}`;
                    //     },
                    //     title: function(row) {
                    //         return row.title;
                    //     },
                    //     tags: function(row) {},
                    //     action: function(url) {
                    //         that.$router.push({ path: url });
                    //         that.hideSearch();
                    //     },
                    // },
                    // "tools": {
                    //     url: function(row) {
                    //         return `${row.url}`;
                    //     },
                    //     title: function(row) {
                    //         return row.title;
                    //     },
                    //     tags: function(row) {},
                    //     action: function(url) {
                    //         that.$router.push({ path: url });
                    //         that.hideSearch();
                    //     },
                    // },
                    // "news": {
                    //     url: function(row) {
                    //         return `/news/content/${row.mid}`;
                    //     },
                    //     title: function(row) {
                    //         return row.title;
                    //     },
                    //     tags: function(row) {},
                    //     action: function(url) {
                    //         that.$router.push({ path: url });
                    //         that.hideSearch();
                    //     },
                    // },
                    // "technical": {
                    //     url: function(row) {
                    //         return `/technical/view/${row.mid}?subject=tgju-news-3087005`;
                    //     },
                    //     title: function(row) {
                    //         return row.title;
                    //     },
                    //     tags: function(row) {},
                    //     action: function(url) {
                    //         that.$router.push({ path: url });
                    //         that.hideSearch();
                    //     },
                    // },
                },
            }],
            search_text :'',
            search_cat_text: '',
            search_exchange_text:'',
            search_loading:false,
            scroll_Loading:false,
            active_header:[],
            active_section:[],
            headers:[],
            render:[],
            data:[],
            def:false,
            // categories
            filter:false,
            catShow:false,
            catModal:'',
            catSelect: [],
            catList: [],
            catSelectFilterList: [],
            showExchangeList:false,
            showResultSearch:false,
            exchangeList:[],
            exchangeFilterList:[],
            show_null_result:false,
            awaiting_reply:false,
            // Page
            page: 1,
            last:false,
            navigation_value: 0,
            drawer: false,
            group: null,
            navigation: false,
            navigation_items: [

                {
                    icon: 'uil uil-shop',
                    title: 'بازارها',
                    route: 'MarketRedirect',
                    items: [
                        { icon: 'uil uil-university', title: 'بازارهای داخلی', route: 'MarketRedirect' },
                        { icon: 'uil uil-university', title: 'بازارهای جهانی', route: '/market/global-markets', link: true },
                        { icon: 'uil uil-university', title: 'بازار خرده فروشی', route: '/retail/retail-market', link: true },
                    ]
                },
                {
                    icon: 'uil uil-university',
                    title: 'صرافی‌ها',
                    route: 'CurrencyExchange',
                    items: [
                        { icon: 'uil uil-university', title: 'صرافی‌های ارزی', route: 'CurrencyExchange' },
                        { icon: 'uil uil-university', title: 'صرافی‌های داخلی‌کریبتو', route: 'LocalExchanges' },
                        { icon: 'uil uil-university', title: 'صرافی‌های جهانی‌کریبتو', route: 'GlobalExchanges' },
                    ]
                },
                {
                    icon: 'uil uil-globe',
                    title: 'شاخص‌ها',
                    route: 'CurrencyExchange',
                    items: [
                        { icon: 'uil uil-globe', title: 'شاخص‌ها', route: 'EconomicsCountryListCategory' },
                        { icon: 'uil uil-globe', title: 'کشورها', route: 'EconomicsAtlas' },
                    ]
                },
                { icon: 'uil uil-newspaper', title: 'اخبـار', route: 'NewsIntro' },
                { icon: 'uil uil-comments-alt', title: 'گفت‌و‌گوها', route: 'Forums' },
                { icon: 'uil uil-chart', title: 'تحلیل‌ها', route: 'NewTechnical' },
                { icon: 'uil uil-create-dashboard', title: 'داشبورد‌ها', route: 'Dashboards' },
                { icon: 'uil uil-bill', title: 'سبد دارایی', route: 'Investment' },
                {
                    icon: 'uil uil-apps',
                    title: 'ابزارها',
                    route: 'ToolsIntro',
                    items: [
                        { icon: 'uil uil-apps', title: 'ابزارها', route: 'ToolsIntro' },
                        { icon: 'uil uil-bell', title: 'هشدارها', route: 'AlertsView' },
                        { icon: 'uil uil-list-ui-alt', title: 'واچ‌لیست‌ها', route: 'WatchListsView' },
                        // { icon: 'uil uil-bookmark-full', title: 'بوک‌مارک‌ها', route: 'FavoritesView' },
                        { icon: 'uil uil-list-ol-alt', title: 'وظایف روزانه', route: 'TodoView' },
                        { icon: 'uil uil-notes', title: 'یادداشت‌ها و اسناد', route: 'NotesView' },
                        { icon: 'uil uil-apps', title: 'سایر ابزارها', route: 'ToolsIntro' },
                    ]
                },
                {
                    icon: 'uil uil-user',
                    title: 'حساب کاربری',
                    route: 'Profile',
                    items: [
                        { icon: 'uil uil-shop', title: 'نمایه کاربر', route: 'Profile' },
                        { icon: 'uil uil-university', title: 'حساب کاربری', route: 'ProfileEdit' },
                    ]
                },
                // { icon: 'uil uil-user-square', title: 'حساب کاربری', route: 'ProfileEdit' },
                // { icon: 'uil uil-setting', title: 'تنظیمات', route: 'Settings' },
                { icon: 'uil uil-signout', title: 'خروج', route: 'Logout' },
            ],
            more_items: [
                { icon: 'uil uil-comment-dots', title: 'پشتیبانی هوشمند', route: 'SmartChat' },
                { icon: 'uil uil-comments', title: 'پشتیبانی آنلاین', route: 'Chat' },
                { icon: 'uil uil-tv-retro', title: 'پخش‌های زنده', route: 'Stream' },
                { icon: 'uil uil-robot', title: 'ربات‌های پاسخگو', route: 'Robot' },
                { icon: 'uil uil-image-share', title: 'شبکه‌های اجتماعی', route: 'Social' },
                { icon: 'uil uil-apps', title: 'افزونه‌های تلگرام', route: 'Social' },
                { icon: 'uil uil-university', title: 'قوانین', route: 'Terms' },
                { icon: 'uil uil-bug', title: 'گزارش خطا', route: 'Report' },
                { icon: 'uil uil-envelope-alt', title: 'تماس با ما', route: 'Contact' },
                { icon: 'uil uil-signout', title: 'خروج', route: 'Logout' },
            ],
            ads: null,
            ads2: null,
        }
    },
    mounted() {
        this.$watch('data', function (newValue, oldValue) {
            this.indicator = undefined;
        }, { immediate: true });


        this.render = this.config[0].render;
        this.headers = this.config[0].header;
        this.active_header = this.headers[0];
        this.active_section = this.active_header.sections[0];
        this.scrollLoader();
        
    },
    methods: {
        getExchangeList(category_id){
            this.showResultSearch=false
            var that = this;
            let api_data = {
                category_id: category_id,
            };
            that.show_null_result=false
            that.awaiting_reply=true
            this.$helpers.makeRequest('GET', '/watchlist/exchange', api_data).then(api_response => {
                that.awaiting_reply=false
							if ( api_response.status == 200 ) {
								that.exchangeList=api_response.data.response.excange_markets

                                if (api_response.data.response.excange_markets.length==0) {
                                    that.show_null_result=true
                                }
							}else{
                                that.show_null_result=true
                            }
						});
        },

        setExchange(item){
            item.n1=item.item_id
            this.indicator=item
            this.showExchangeList=false;
            this.exchangeList=[],
            this.hideSearch();
        },

        backExchange(){
            this.showExchangeList = false;
            this.exchangeList=[];
            this.exchangeFilterList=[];
        },

        // این متد وظیفه ارسال درخواست به پرنت جهت افزودن آیتم جدید به واچ لیست را دارد	
        saveChanges() {
            this.$parent.emitData('addWatchList', { market_id: this.indicator.n1 }, true);
        },
        // این متد وطیفه بازکردن و نمایش مودال شاخص یاب را دارد
        openDiagram() {
            this.showSearch();

            // let callbackEvent = 'jQuery.hColumns' + new Date().getTime();
            // localStorage.setItem('jQuery_hColumns_event', callbackEvent);

            // this.$root.$on(callbackEvent, (data) => {
            //     this.$root.$off(callbackEvent);
            //     this.indicator = data;
            // });

            // $('#indicator-modal').show();
            // $('#indicator-modal #columns2').html('');
            // this.$helpers.diagrams_render_with_full_selection();
        },



        // (برای استفاده از سرچ اضافه شده است) توابع مربوط به جستجو
        search(page) {
            console.log('search');
            var source = '';
            var that = this;
            var url = "";
            console.log(page);
            // اسکرول لودر
            if (!page) {
                this.search_loading = true;
            } else {
                if (that.last == false) {
                    this.scroll_Loading = true;
                }
            }
            // بررسی سرچ
            if (this.search_text.toLowerCase().length > 2) {
                source = this.active_section.source + '&q=' + this.search_text.toLowerCase();
            } else {
                source = this.active_section.source;
            }

            // بررسی کانفیگ دسته‌بندی‌ها
            if (this.catSelect != undefined) {
                Object.keys(this.catSelect).filter(function (el) {
                    source += '&' + el + '=' + that.catSelect[el].slug.toLowerCase();
                });
            }

            // برای لود صفحه بعدی
            if (!page) {
                url = source;
            } else {
                url = source + '&page=' + page;
            }
            // ریکوئست دیتا
            setTimeout(function () {
                // ریکوئست دریافت دیتا
                var xhr = new XMLHttpRequest();
                xhr.onreadystatechange = function () {
                    if (xhr.readyState == XMLHttpRequest.DONE) {
                        var res = JSON.parse(xhr.responseText);
                        var filterCheck = false;
                        if (res.category) {
                            that.catList = res.category;
                            Object.values(that.catList).filter(function (el) {
                                if (Object.keys(el).length != 0) {
                                    if (el.list != undefined) {
                                        filterCheck = true;
                                    }
                                }
                            });
                            if (filterCheck) {
                                that.filter = true;
                            } else {
                                that.filter = false;
                            }
                        }
                        if (res.default == true) {
                            that.def = true;
                        } else {
                            that.def = false;
                        }
                        if (page) {
                            if (that.last == false) {
                                Array.prototype.push.apply(that.data, res.list);
                                that.scroll_Loading = false;
                            }
                            if (res.last == true) {
                                that.last = true;
                                that.page = 1;
                                that.scroll_Loading = false;
                            }
                        } else {
                            that.last = false;
                            that.data = res.list;
                            that.search_loading = false;
                        }
                    }
                }
                xhr.open('GET', url, true);
                xhr.send(null);
            }, 100);
            this.scrollLoader();
        },
        // فیلتر کشورها
        filteredItems() {
            console.log('catSelectFilterList');
            var that = this;
            var newArray = Object.values(this.catList[this.catModal].list).filter(function (el) {
                return el.name.toLowerCase().indexOf(that.search_cat_text.toLowerCase()) > -1 || el.slug.toLowerCase().indexOf(that.search_cat_text.toLowerCase()) > -1
            });
            if (newArray.length == 0) {
                this.catSelectFilterList = [{ result: false }];
            } else {
                this.catSelectFilterList = newArray;
            }
        },

        filteredExchangeItems(exchangeList){
            console.log('filteredExchangeItems');
            console.log(exchangeList);
            var that = this;
            var newArray = Object.values(exchangeList).filter(function (el) {
                return el.title.toLowerCase().indexOf(that.search_exchange_text.toLowerCase()) > -1 || el.slug.toLowerCase().indexOf(that.search_exchange_text.toLowerCase()) > -1
            });
            this.showResultSearch=true
            this.exchangeFilterList=newArray;

            //if (newArray.length == 0) {
            //    this.exchangeList = [{ result: false }];
            //} else {
            //    this.exchangeList = newArray;
            //}
        },

        showSubActions(item) {
            console.log('showSubActions');
            const ItemElm = document.querySelector('.' + item);
            ItemElm.classList.toggle('showSub');
        },
        setCatList(item, value) {
            console.log('setCatList');
            console.log(item);
            console.log(value);
            if (item == null) {
                this.catModal = '';
                this.catSelectFilterList = [];
            } else {
                this.catModal = item;
                this.catSelectFilterList = value;
            }
            // this.search();
        },
        setCat(item, key) {
            console.log('setCat');
            if (item == null) {
                delete this.catSelect[key];
            } else {
                this.catSelect[this.catModal] = item;
            }
            this.catSelectFilterList = [];
            this.search();
        },
        setCatTools(item) {
            this.catSelect["categories"] = {
                slug: item.title,
                name: item.title,
                flag: false,
                sort: 2
            };
            this.catSelectFilterList = [];
            this.search();
        },
        selectHeader(data) {
            // ست تب اصلی از کانفیگ
            this.catSelect = [];
            this.active_header = data;
            this.active_section = data.sections[0];
            this.page = 1;
            this.search();
        },
        selectSection(data) {
            // ست ساب تب از کانفیگ
            this.catSelect = [];
            this.active_section = data;
            this.page = 1;
            this.search();
        },
        showSearch(render = true) {
            // نمایش سرچ
            // this.render = this.config.render;
            // this.headers = this.config.header;
            // this.active_header = this.headers[0];
            // this.active_section = this.active_header.sections[0];
            document.getElementById("tgsearch").style.display = "block";
            this.search();
        },
        hideSearch() {
            // مخفی کردن سرچ
            var tgsearch = document.getElementById("tgsearch");
            tgsearch.style.display = "none";
        },
        searchClear() {
            // خالی کردن اینپوت سرچ
            this.search_text = '';
            this.search_loading = false;
            this.search();
        },
        scrollLoader() {
            var that = this;
            // لود اسکرول سرچ
            const listElm = document.querySelector('#scrollLoader-content');
            listElm.addEventListener('scroll', e => {
                if (this.scroll_Loading === false) {
                    if (that.last === false) {
                        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
                            that.page = that.page + 1
                            that.search(that.page);
                        }
                    }
                }
            });
        },
    },
}
</script>
